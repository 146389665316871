import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { termModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import Button from "../elements/Button";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const TermModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(termModalState);

  const { data } = useSWR(modal?.type && `/term?type=${modal?.type}`);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    if (window.location.href.includes("/term"))
      navigate("/main", { replace: true });
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  const handleClick = () => {
    modal?.onClick && modal?.onClick();
    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef} className="ql-snow">
        <PopupHeader>
          {modal?.type === "service"
            ? "서비스 이용약관"
            : modal?.type === "privacy"
            ? "개인정보 수집 및 처리방침"
            : modal?.type === "third"
            ? "개인정보 제3자 제공 동의"
            : modal?.type === "place"
            ? "위치 기반 서비스 이용 동의"
            : modal?.type === "purchase"
            ? "구매 약관"
            : ""}
          <Close onClick={handleClose} />
        </PopupHeader>
        <PopupText
          className={"ql-editor" + (modal?.onClick ? " button" : "")}
          dangerouslySetInnerHTML={{ __html: data?.term?.contents }}
        />
        {modal?.onClick && (
          <ButtonArea>
            <Button onClick={handleClick}>확인하고 동의합니다.</Button>
          </ButtonArea>
        )}
      </Wrapper>
    </ModalWrapper>
  );
};

export default TermModal;

const ButtonArea = styled.div`
  padding: 20px;
`;

const PopupText = styled.div`
  padding: 11px 20px 20px;
  width: 100%;
  height: calc(100% - 58px);
  overflow: auto;

  &.button {
    padding: 11px 20px;
    height: calc(100% - 58px - 88px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
